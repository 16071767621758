import { default as reactToast } from "react-hot-toast";
import { ToastInfo } from "@/components/Toast";
const info = ({ title, subtitle, icon, onClick }) => {
  const toastId = reactToast.custom((t) => (
    <ToastInfo
      icon={icon}
      visible={t.visible}
      title={title}
      subtitle={subtitle}
      onClose={() => reactToast.dismiss(toastId)}
      onClick={onClick}
    />
  ));
  return toastId;
};

export default { info };
