import axios from "axios";
import { identify } from "@/lib/analytics";

export const AUTH_URL = `${process.env.NEXT_PUBLIC_API_HOST}/spree_oauth/token`;
const AUTH_NAMESPACE = "_ph_stores_auth";

export const setAuth = (data) =>
  localStorage.setItem(AUTH_NAMESPACE, JSON.stringify(data)) && data;

export const getAuth = () => JSON.parse(localStorage.getItem(AUTH_NAMESPACE));

export const clearAuth = () => localStorage.setItem(AUTH_NAMESPACE, null);

const handleError = (error) => {
  if (error.response.status === 400) {
    clearAuth();
    throw "incorrect_credentials";
  }
};

export const loginWithCredentials = (username, password) =>
  axios
    .post(AUTH_URL, {
      grant_type: "password",
      scope: "admin",
      username,
      password,
      client_id: process.env.NEXT_PUBLIC_OAUTH_APP_UID,
      client_secret: process.env.NEXT_PUBLIC_OAUTH_APP_SECRET,
    })
    .then((res) => {
      setAuth(res.data);
      identify(res.data.uuid, { email: res.data.email });
      return res.data;
    })
    .catch(handleError);

export const rotateToken = () =>
  axios
    .post(AUTH_URL, {
      grant_type: "refresh_token",
      refresh_token: getAuth()?.refresh_token,
      client_id: process.env.NEXT_PUBLIC_OAUTH_APP_UID,
      client_secret: process.env.NEXT_PUBLIC_OAUTH_APP_SECRET,
    })
    .then((res) => {
      identify(res.data.uuid, { email: res.data.email });
      setAuth(res.data);
      return res.data;
    })
    .catch(handleError);

export const signup = (email, password, passwordConfirmation) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_API_HOST}/api/v2/platform/admin_registrations`,
    {
      admin: {
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
    }
  );
