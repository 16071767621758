import usePlatformApi from "@/lib/platformApi";

export default function useStoreList({ disableRedirect, ...params } = {}) {
  const { data = {}, ...rest } = usePlatformApi(
    `${process.env.NEXT_PUBLIC_API_HOST}/api/v2/platform/stores`,
    {
      params,
      disableRedirect,
    }
  );

  return { ...data, ...rest };
}
