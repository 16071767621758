import { MdNotifications } from "react-icons/md";
import { HiX } from "react-icons/hi";

export function ToastInfo({
  visible,
  title,
  subtitle,
  icon,
  action,
  onClose,
  onClick,
}) {
  return (
    <div
      className={`${
        visible ? "animate-enter" : "animate-leave"
      } pointer-events-auto w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex">
        <div className="flex-shrink-0 p-3">
          <MdNotifications
            className="h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <div className="w-0 flex-1 py-3">
          <p className="text-sm font-medium text-gray-900">{title}</p>
          <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
        </div>
        <div className="flex flex-col items-stretch divide-y border-l">
          <button
            onClick={onClick}
            type="button"
            className="flex-1 items-center px-3 text-sm font-medium hover:bg-gray-50 "
          >
            View
          </button>
          <button
            onClick={onClose}
            type="button"
            className="flex-1 items-center px-3 text-sm font-medium hover:bg-gray-50"
          >
            Dismiss
          </button>
        </div>
        <div className="xflex ml-4 hidden flex-shrink-0">
          <button
            type="button"
            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <HiX className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
