const formatterUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 3,
});

// Previous formatter for ref:
// const formatPrice = (price) =>
//   price ? (Math.round(price * 100) / 100).toFixed(2) : "0.00";

const formatPrice = (price) => formatterUSD.format(price ? price : 0);

export { formatPrice };
