import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuth, rotateToken } from "@/lib/auth";
import { deserialise } from 'kitsu-core';

// Add Access Token
const addAccessTokenInterceptor = (config) => {
  const authResponse = getAuth();

  if (authResponse?.access_token) {
    config.headers["Authorization"] = "Bearer " + authResponse?.access_token;
  }

  return config;
};

axios.interceptors.request.use(addAccessTokenInterceptor);

// Retry with rotated access token
const refreshAuthLogic = (failedRequest) =>
  rotateToken().then((authResponse) => {
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + authResponse.refresh_token;
    return Promise.resolve();
  });

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);

export const newInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(addAccessTokenInterceptor);
  createAuthRefreshInterceptor(instance, refreshAuthLogic);

  return instance;
};

export const newJsonApiInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });
  instance.interceptors.request.use(addAccessTokenInterceptor);
  instance.interceptors.response.use(
    (config) => {
      config.data = deserialise(config.data);

      return config;
    },
    (error) => {
      // Do something with the request error
      return Promise.reject(error);
    }
  );
  createAuthRefreshInterceptor(instance, refreshAuthLogic);

  return instance;
};

export const newNonStandardJsonApiInstance = newInstance;

export default axios;
