import useSWR, { useSWRConfig } from "swr";
import { newJsonApiInstance } from "@/lib/axios";
import { useRouter } from "next/router";
import { useEffect } from "react";
import qs from "qs";

export default function usePlatformApi(
  url,
  { params, fetcher, disableRedirect } = {}
) {
  const baseURL = `${process.env.NEXT_PUBLIC_API_HOST}/api/v2/platform`;

  const api = newJsonApiInstance(baseURL);

  const { data, error, isValidating, mutate } = useSWR(
    url ? `${url}?${qs.stringify(params)}` : null,
    fetcher
      ? fetcher
      : (url) => api.get(url, { params }).then((res) => res?.data)
  );
  const { mutate: globalMutate } = useSWRConfig();
  const router = useRouter();

  useEffect(() => {
    if (
      error === "incorrect_credentials" &&
      !isValidating &&
      !disableRedirect
    ) {
      const returnTo = window?.location?.pathname;

      router.push({
        pathname: "/login",
        query: {
          returnTo,
        },
      });
    }
  }, [router, error, isValidating, disableRedirect]);

  return {
    data,
    error,
    isValidating,
    isLoading: !data && !error,
    globalMutate,
    api,
    boundMutate: mutate,
  };
}
