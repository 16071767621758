import Honeybadger from "@honeybadger-io/js";
import "../styles/globals.css";
import Router from "next/router";
import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import { useEffect } from "react";
import useStoreList from "@/lib/useStoreList";
import Script from "next/script";
import { Toaster } from "react-hot-toast";
import { Toaster as SonnerToaster, toast as sonnerToast } from "sonner";
import toast from "@/lib/toast";
import { formatPrice } from "@/lib/currency";
import OrderNotificationContext from "@/components/OrderNotificationContext";
import { subscribeToStoreEvent } from "@/lib/store-notifications";

// https://docs.honeybadger.io/lib/javascript/reference/configuration.html
Honeybadger.configure({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  revision: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  environment: process.env.NODE_ENV,
  projectRoot: "webpack://_N_E/./",

  // Uncomment to report errors in development:
  // reportData: true,
});

Router.events.on("routeChangeStart", () => {
  window.referrerUrl = window.location.href;
});

Router.events.on("routeChangeComplete", () => {
  if (window.pageFnCalledOn !== window.location.href) {
    const referrerUrl =
      window.referrerUrl === window.location.href ? "" : window.referrerUrl;
    window.analytics.page({ referrer: referrerUrl });
  }
  window.pageFnCalledOn = null;
});

function MyApp({ Component, pageProps }) {
  const { data: stores } = useStoreList({
    per_page: 1000,
    "fields[store]": "code",
    disableRedirect: true,
  });

  const getChannelsForStores = (stores) =>
    stores?.map(
      (store) =>
        window.pusher.channel(store?.code) ||
        window.pusher.subscribe(store?.code)
    );

  const bindOrderNotification = () => {
    var channels = getChannelsForStores(stores);
    const audio = new Audio("/new_order_sound.mp3");
    channels.forEach((channel) => {
      channel.unbind("new_order");
      channel.bind("new_order", function (data) {
        const enableOrderNotification =
          localStorage.getItem("enableOrderNotification") === "true";

        if (enableOrderNotification) {
          audio.play();
          toast.info({
            title: "New order received",
            subtitle: `${formatPrice(data.order_total)} on ${data.store_name}`,
            onClick: () => {
              window.open(
                `/s/${data.store_id}/orders/${data.order_id}`,
                "_blank"
              );
            },
          });
        }
      });
    });
  };

  const unbindOrderNotification = () => {
    var channels = getChannelsForStores(stores);
    channels.forEach((channel) => {
      channel.unbind("new_order");
    });
  };

  useEffect(() => {
    const enableOrderNotification =
      localStorage.getItem("enableOrderNotification") === "true";

    if (stores?.length > 0) {
      if (enableOrderNotification) {
        bindOrderNotification();
      }
      stores.forEach((store) => {
        subscribeToStoreEvent(store.code, "store-setup-suceess", () => {
          const toastId = `${store.id}/store-setup-in-progress`;
          sonnerToast.success(`Your store ${store.name} is ready!`, {
            id: toastId,
            duration: 4000,
          });
        });
      });
    }
  }, [stores]);

  return (
    <TooltipProvider delayDuration={350}>
      <OrderNotificationContext.Provider
        value={{ bindOrderNotification, unbindOrderNotification }}
      >
        <Component {...pageProps} />
      </OrderNotificationContext.Provider>
      <Toaster position="bottom-right" />
      <SonnerToaster position="bottom-right" />
    </TooltipProvider>
  );
}

export default MyApp;
