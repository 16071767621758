// Warning: CLIENT SIDE ONLY

// storeId is the store code as set in the backend

export function subscribeToStoreEvent(storeId, event, callback) {
  const channel =
    window.pusher.channel(storeId) || window.pusher.subscribe(storeId);
  channel.unbind(event);
  channel.bind(event, callback);
}

export function unsubscribeFromStoreEvent(storeId, event) {
  const channel =
    window.pusher.channel(storeId) || window.pusher.subscribe(storeId);
  channel.unbind(event);
}
